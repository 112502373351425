import { Button, FuelIcon, Image, Title, Text, Field, TextField } from "components/commons";
import React, { useMemo } from "react";
import locale from "localization";
import { formatNumber, formatAmount, formatVolume, parseLiter } from "utils";
import styles from "./payment-success-page.module.scss";
import { prettifyProduct } from "utils/pretty.utils";
import { initialState as formState } from "./payment-or-details.state";
import { PaymentSuccess } from "images";
import classNames from "classnames";
import { useForm, useMount } from "hooks";
import { PaymentMethod, InvoicingSetup } from "enums";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
const PaymentSuccessPage = ({
  loading,
  dispensedTo,
  literVolume,
  redemptionId,
  onSubmitOR,
  details,
  transactionDetails,
  setDetails,
  reset,
  initialState,
  dataFields,
  paymentId,
  checkCompanyGroup,
  // ...modal
}) => {
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);
  const { fields, modifyField, modifyForm, isFormSubmittable } = useForm({
    initialState: form,
  });

  const discount = dataFields?.totalDiscount.value;

  const subTotal = useMemo(() => {
    return parseFloat(
      parseLiter(dataFields?.actualAmountDispense.value || dataFields?.totalAmount.value)
    );
  }, [dataFields?.actualAmountDispense?.value, dataFields?.totalAmount.value]);

  useMount(() => {
    if (details?.fleet?.invoicingSetup === InvoicingSetup.SingleInvoicing && checkCompanyGroup) {
      modifyForm({
        [fields.orNumber.name]: { validations: [], required: false },
      });
    }
  });

  return (
    <div className={styles.paymentSuccessContainer}>
      <div className={styles.confirmation}>
        <Text className={styles.headerPaymentSuccessTitle} strong>
          {locale.paymentSuccess}
        </Text>
        <div className={styles.titleContainer}>
          <Image src={PaymentSuccess} className={styles.successIcon} />
          <div className={styles.titleContent}>
            <Title medium className={styles.title}>
              {details.paymentMethod === PaymentMethod.FleetCard ||
              details.paymentMethod === PaymentMethod.FleetCardManualPO ? (
                <>{formatAmount(subTotal - discount)}</>
              ) : (
                <locale.Populate
                  text={literVolume === 1 ? locale.nLiter : locale.nLiters}
                  items={[formatNumber(literVolume)]}
                />
              )}
            </Title>
            <Text>
              <locale.Populate
                text={locale.ofText}
                className={styles.fuel}
                items={[
                  <span className={styles.fuel}>
                    <FuelIcon
                      className={styles.icon}
                      type={
                        details.paymentMethod === PaymentMethod.FleetCard ||
                        details.paymentMethod === PaymentMethod.FleetCardManualPO
                          ? dataFields?.productType?.value
                          : details.productCode
                      }
                    />
                    <span className={styles.productFuel}>
                      {prettifyProduct(
                        details.paymentMethod === PaymentMethod.FleetCard ||
                          details.paymentMethod === PaymentMethod.FleetCardManualPO
                          ? dataFields?.productType?.value
                          : details.productCode
                      )}
                    </span>
                  </span>,
                ]}
              />
            </Text>
            <Text>{locale.hasBeenSuccessfullyRedeemed}</Text>
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <label>{locale.businessName}</label>
            <label>{details.fleet?.businessName || ""}</label>
          </div>
          <div className={styles.row}>
            <label>{locale.transactionId}</label>
            <label>
              {details.paymentMethod === PaymentMethod.FleetCard ||
              details.paymentMethod === PaymentMethod.FleetCardManualPO
                ? paymentId || locale.any
                : transactionDetails?.redemptionId || locale.any}
            </label>
          </div>
          <div className={styles.row}>
            <label>{locale.eligibleVehicle}</label>
            <label
              className={classNames({
                italic: !details.vehiclePlateNumber,
              })}
            >
              {details.vehiclePlateNumber || locale.any}
            </label>
          </div>
          {details.paymentMethod === PaymentMethod.FleetCard ||
          details.paymentMethod === PaymentMethod.FleetCardManualPO ? (
            <>
              <div className={styles.row}>
                <div className={styles.horizontalLine}></div>
              </div>

              {dataFields?.productType?.value !== locale.lubes && (
                <>
                  <div className={styles.row}>
                    <label>{locale.computedVolume}</label>
                    <label>{formatVolume(dataFields.computedVolume.value || 0)}</label>
                  </div>
                  <div className={styles.row}>
                    <label>{locale.pumpPrice}</label>
                    <label>{formatAmount(dataFields?.pumpPrice.value || 0)}</label>
                  </div>
                  <div className={styles.row}>
                    <label>{locale.totalDiscount}</label>
                    <label>
                      {dataFields?.totalDiscount.value > 0 && <>-</>}
                      {formatAmount(dataFields?.totalDiscount.value)}
                      <span
                        style={{ color: "#a1aeb7", fontSize: "12px", marginLeft: "5px" }}
                      >{`(${formatAmount(dataFields?.discountPerLiter.value)} / L)`}</span>
                    </label>
                  </div>
                </>
              )}

              <div className={styles.row}>
                <label>{locale.totalAmount}</label>
                <label>{formatAmount(subTotal - discount)}</label>
              </div>
            </>
          ) : (
            <>
              <div className={styles.row}>
                <label>{locale.averageFullCreditPrice}</label>
                <label>{formatAmount(transactionDetails?.averageCreditPrice) || locale.any}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.totalCreditAmount}</label>
                <label>{formatAmount(transactionDetails?.totalCreditPrice) || locale.any}</label>
              </div>
            </>
          )}
          {details?.fleet?.invoicingSetup === InvoicingSetup.SingleInvoicing &&
          checkCompanyGroup ? (
            <></>
          ) : (
            <div className={styles.row}>
              <Field className={styles.fieldContainer} {...fields.orNumber}>
                <TextField
                  {...fields.orNumber}
                  className={styles.formContainer}
                  onChange={modifyField}
                />
              </Field>
            </div>
          )}
        </div>
        {details?.fleet?.invoicingSetup === InvoicingSetup.SingleInvoicing && checkCompanyGroup && (
          <div className={styles.noteDoNotIssueReceiptSection}>
            <div className={styles.noteDoNotIssueReceipt} style={{ width: "100%" }}>
              <ErrorOutline className={styles.warningSign} />
              <div className={styles.warningTextSection}>
                <span className={styles.warningTextSingleInvoice}>
                  {locale.plbAccountIsInSingleInvoicing}
                </span>
                <span className={styles.warningTextDoNotIssue}>{locale.doNotIssueReceipt}</span>
              </div>
            </div>
          </div>
        )}
        <Button
          primary
          className={styles.doneButton}
          disabled={!isFormSubmittable}
          loading={loading}
          onClick={() => {
            onSubmitOR(fields.orNumber);
            setDetails(null);
            reset();
          }}
        >
          {locale.done}
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
