import { useContext } from "react";
import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import { Path, StationStatus } from "enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import locale from "localization";
import { FeatureFlagContext, StationContext, UserContext } from "contexts";
import { Role, Portal } from "enums";

const Navigation = ({
  isPlbOnboarded,
  isPlcOnboarded,
  isVip,
  showPremiumPayment,
  status,
  stationCode,
}) => [
  isPlbOnboarded && {
    label: locale.payment,
    icon: "icon-cash-flow",
    link: Path.Payment,
    key: "PAYMENT",
    items: [
      isPlbOnboarded
        ? {
            label: locale.validatePaymentCapitalize,
            child: true,
            link: Path.ValidatePayment,
            key: "VALIDATE_PAYMENT",
            permission: [Role.PortalAdmin, Role.StationCashier],
          }
        : null,
      // { label: locale.paymentHistoryCapitalize, child: true, link: Path.PaymentHistory1 },
    ],
    permission: [Role.PortalAdmin, Role.StationCashier],
  },
  isPlbOnboarded && {
    label: locale.paymentHistoryCapitalize,
    icon: "icon-icon-clock",
    link: Path.PaymentHistory,
    key: "PAYMENT_HISTORY",
    items: [
      isPlbOnboarded
        ? {
            label: locale.forBusiness,
            child: true,
            link: Path.PaymentHistoryBusiness,
            key: "PAYMENT_HISTORY_BUSINESS",
            permission: [Role.PortalAdmin, Role.StationCashier, Role.Accounting],
          }
        : null,
      status === StationStatus.Active
        ? {
            // label: locale.plbLocqpayFleetCard,
            label: locale.plbLocqpay,
            child: true,
            link: Path.PayWithLOCQPay,
            key: "PAY_WITH_LOCQPAY",
            permission: [Role.PortalAdmin, Role.StationCashier, Role.Accounting],
          }
        : null,
      status === StationStatus.Active
        ? {
            label: locale.plbLOCQPayNonFuel,
            child: true,
            link: Path.LOCQPayNonFuel,
            key: "PAYMENT_HISTORY_PLB_LOCQPAY_NON_FUEL",
            permission: [Role.PortalAdmin, Role.StationCashier, Role.Accounting],
          }
        : null,
      status === StationStatus.Active
        ? {
            label: locale.forConsumer,
            child: true,
            link: Path.PaymentHistoryConsumer,
            key: "PAYMENT_HISTORY_CONSUMER",
            permission: [Role.PortalAdmin, Role.StationCashier, Role.Accounting],
          }
        : null,
      status === StationStatus.Active
        ? {
            label: locale.plcLocqpay,
            child: true,
            link: Path.PayWithPeso,
            key: "PAY_WITH_PESO",
            permission: [Role.PortalAdmin, Role.StationCashier, Role.Accounting],
          }
        : null,
      status === StationStatus.Active
        ? {
            label: locale.locqPayForNonFuel,
            child: true,
            link: Path.NonFuel,
            key: "LOCQPAY_NON_FUEL",
            permission: [Role.PortalAdmin, Role.StationCashier, Role.LubeServ, Role.Accounting],
          }
        : null,
      {
        label: locale.settlementHistory,
        child: true,
        link: Path.SettlementHistory,
        key: "SETTLEMENT_HISTORY",
        permission: [Role.PortalAdmin, Role.Accounting],
      },
      showPremiumPayment
        ? {
            label: locale.premiumPayment,
            child: true,
            link: Path.PremiumPayout,
            key: "PREMIUM_PAYOUT",
            permission: [Role.PortalAdmin, Role.Accounting],
          }
        : null,
    ],
    permission: [Role.PortalAdmin, Role.StationCashier, Role.LubeServ, Role.Accounting],
  },
  isVip && {
    label: locale.vipLoyalty,
    link: Path.VIPAccount,
    key: "VIP_ACCOUNT",
    icon: "icon-icon-diamond",
    exact: true,
    permission: [Role.PortalAdmin, Role.StationCashier],
  },
  {
    label: locale.otherServices,
    icon: "icon-gear",
    link: Path.OtherServices,
    key: "STATION_OTHER_SERVICES",
    items: [
      {
        label: locale.lubeServOnSite,
        link: Path.LubeServOnSiteBookings,
        key: "LUBESERV_ON_SITE_BOOKINGS",
        child: true,
        permission: [
          stationCode === 4377 ||
          stationCode === 4506 ||
          stationCode === 4496 ||
          stationCode === 4636 ||
          stationCode === 5009 ||
          stationCode === 4636 ||
          stationCode === 4496
            ? Role.PortalAdmin
            : null,
          stationCode === 4377 ||
          stationCode === 4506 ||
          stationCode === 4496 ||
          stationCode === 4636 ||
          stationCode === 5009 ||
          stationCode === 4636 ||
          stationCode === 4496
            ? Role.LubeServ
            : null,
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.LubeServ],
  },
  {
    label: locale.accessAuthorization,
    link: Path.AccessAuthorization,
    key: "ACCESS_AUTHORIZATION",
    icon: "icon-access",
    items: [
      {
        label: locale.userAccess,
        child: true,
        link: Path.UserAccess,
        permission: [Role.PortalAdmin],
      },
    ],
    permission: [Role.PortalAdmin],
  },
  (isPlbOnboarded || isPlcOnboarded) && {
    label: locale.generateQrCode,
    icon: "icon-qr_code_scanner",
    link: Path.GenerateQrPreview,
    key: "GENERATE_QR",
    exact: true,
    permission: [Role.PortalAdmin, Role.LubeServ],
  },
];

const ChildItem = ({ item, role }) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <Accordion elevation={0} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
        <NavLink
          to={item.link}
          className={classNames(styles.listItem, styles.disableLink)}
          activeClassName={classNames(item, styles.selectedMenu)}
        >
          <span className={styles.item}>
            <span className={styles.listIconRoot}>
              {item.icon && <span className={`icon ${item.icon}`} />}
            </span>
            <span className={styles.listText}>{item.label}</span>
          </span>
        </NavLink>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.child}>
          {item.items
            ?.filter((child) => {
              return child?.key ? hasFeature(`PAGE_${child?.key}`) : true;
            })
            .map((child, childKey) => {
              if (!child) return <></>;
              return child.permission?.map((permission) => {
                return permission === role ? (
                  <NavLink
                    key={childKey}
                    to={child.link}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.listIconRoot}>
                      {child.icon && <span className={`icon ${child.icon}`} />}
                    </span>
                    <span className={styles.listText}>{child.label}</span>
                  </NavLink>
                ) : (
                  <></>
                );
              });
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const Sidebar = () => {
  const { station } = useContext(StationContext);
  const { stationCode } = station;
  const { hasFeature } = useContext(FeatureFlagContext);
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses.find((element) => {
    return element.portal === Portal.STATION;
  });
  return (
    <div className={styles.sidebarContainer}>
      <List className={styles.sidebarList}>
        {Navigation({
          isPlcOnboarded: station?.isPlcOnboarded,
          isPlbOnboarded: station?.isPlbOnboarded,
          isVip: station?.isVip,
          showPremiumPayment: [
            "39d0055e-696c-4ee1-8362-98d1538873cd",
            "c46de8ae-f2e9-40a5-801c-e5465f8172ab",
          ].includes(user.userId),
          status: station?.status,
          stationCode: stationCode,
        })
          .filter((child) => {
            return child?.key ? hasFeature(`PAGE_${child?.key}`) : true;
          })
          .map((item, index) => {
            if (!item) return <></>;

            if (!user.userAccesses)
              return (
                <span key={index}>
                  {item.exact ? (
                    <NavLink
                      to={item.link}
                      exact={true}
                      className={classNames(styles.listItem)}
                      activeClassName={styles.active}
                    >
                      <span className={styles.item}>
                        <span className={styles.listIconRoot}>
                          {item.icon && <span className={`icon ${item.icon}`} />}
                        </span>
                        <span className={styles.listText}>{item.label}</span>
                      </span>
                    </NavLink>
                  ) : (
                    <ChildItem item={item} role={role} />
                  )}
                </span>
              );

            return item.permission?.map((permission) => {
              return permission === role ? (
                <span key={index}>
                  {item.exact ? (
                    <NavLink
                      to={item.link}
                      exact={true}
                      className={classNames(styles.listItem)}
                      activeClassName={styles.active}
                    >
                      <span className={styles.item}>
                        <span className={styles.listIconRoot}>
                          {item.icon && <span className={`icon ${item.icon}`} />}
                        </span>
                        <span className={styles.listText}>{item.label}</span>
                      </span>
                    </NavLink>
                  ) : (
                    <ChildItem item={item} role={role} />
                  )}
                </span>
              ) : (
                <></>
              );
            });
          })}
      </List>
    </div>
  );
};

export default Sidebar;
