import { CircularProgress } from "@material-ui/core";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import React, { useMemo, useState, useContext } from "react";
import {
  ActionButton,
  Autocomplete,
  Field,
  FuelIcon,
  Text,
  Title,
  CardIcon,
  TextField,
} from "components/commons";
import { InputLitre, InputAmount } from "components/field";
import locale from "localization";
import InsufficientErrorPage from "./insufficient-error-page";
import { initialState as formState } from "./validate-payment-details.state";
import styles from "./validate-payment.module.scss";
import ValidatePaymentConfirmPage from "./validate-payment-confirm-page";
import PaymentErrorPage from "./payment-error-page";
import PaymentSuccessPage from "./payment-success-page";
import ProceedToGasModal from "./proceed-to-gas-modal";
import { useApi, useForm, useModal, useMount } from "hooks";
import { prettifyDispenseType, prettifyProduct } from "utils/pretty.utils";
import {
  formatVolume,
  handleRequest,
  parseLiter,
  formatAmount,
  roundUpTwoDecimal,
  roundUp,
} from "utils";
import {
  getFuelBalance,
  redeemFuelCode,
  updateRedemption,
  createPayment,
  createPaymentLubes,
  updatePayment,
  updatePaymentNonFuel,
} from "apis";
import _ from "lodash";
import { DispenseType, ErrorCode, PaymentMethod, PLBTypes, InvoicingSetup } from "enums";
import classNames from "classnames";
import { StationContext } from "contexts";
import GasFuelCard from "./gas-fuel-card";
import { ProceedToGas } from "images";
import { NotifModal } from "components/modals";
import ZeroBalanceModal from "./zero-balance-modal";

const ValidatePaymentDetails = (props, { initialState }) => {
  const { station } = useContext(StationContext);

  const checkCompanyGroup = useMemo(() => {
    const str = station?.businessName;
    const res = str.includes("GAZZ UP, INC.") || str.includes("SERV CENTRAL, INC.");
    return res;
  }, [station?.businessName]);

  const { details, setPaymentDetails, setDetails, clearForm, onSuccessCallback, onActiveStepCB } =
    props;
  const [showGasUp, setGasUp] = useState(false);
  const [showProceedToPayment, setProceedToPayment] = useState(false);
  const [showSuccessPayment, setSuccessPayment] = useState(false);
  const [showPaymentError, setPaymentError] = useState(false);
  const [showInsufficientError, setInsufficientError] = useState(false);
  const [fuelBalance, setFuelBalance] = useState(null);
  const [redemptionId, setRedemptionId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [redemptionResponse, setRedemptionResponse] = useState(null);
  const errorModal = useModal();
  const confirmModalGasUp = useModal();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const zeroBalanceModal = useModal();
  const balanceErrorCheck = isBalanceValue();

  function isBalanceValue() {
    let hasBalanceError = null;
    if (
      details?.paymentMethod === PaymentMethod.FleetCard ||
      details?.paymentMethod === PaymentMethod.FleetCardManualPO
    ) {
      if (details?.wallet === 0) {
        hasBalanceError = true;
      } else if (details?.remainingLocqpayLimit === 0) {
        hasBalanceError = true;
      } else if (details?.remainingTransactionLimit === 0) {
        hasBalanceError = false;
      }
    }
    return hasBalanceError;
  }
  const { fields, modifyField, modifyForm, isFormSubmittable, getFormValues, applyFieldErrors } =
    useForm({
      initialState: form,
    });

  const { request: getFuelBalanceRequest, loading: fetchingFuelBalance } = useApi({
    api: getFuelBalance,
    params: {
      fleetId: details.fleetId,
    },
    pageError: true,
  });

  const { request: redeemFuelCodeRequest, loading: redeemingFuelCode } = useApi({
    api: redeemFuelCode,
    params: {
      fleetId: details.fleetId,
    },
    pageError: false,
    modalError: false,
  });

  const { request: createPaymentFleetCardRequest, loading: creatingPaymentFleetCard } = useApi({
    api: createPayment,
    pageError: false,
    modalError: false,
  });

  const { request: createPaymentFleetCardLubesRequest, loading: creatingPaymentFleetCardLubes } =
    useApi({
      api: createPaymentLubes,
      pageError: false,
      modalError: false,
    });

  const { request: updateRedemptionORRequest, loading: updatingRedemptionOR } = useApi({
    api: updateRedemption,
    pageError: false,
    modalError: false,
  });

  const { request: updatePaymentORRequest, loading: updatingPaymentOR } = useApi({
    api: updatePayment,
    pageError: false,
    modalError: false,
  });

  const { request: updatePaymentORNonFuelRequest, loading: updatingPaymentORNonFuel } = useApi({
    api: updatePaymentNonFuel,
    pageError: false,
    modalError: false,
  });

  useMount(async () => {
    if (balanceErrorCheck != null) {
      zeroBalanceModal.show();
      return;
    }

    handleRequest(async () => {
      const res = await getFuelBalanceRequest();
      if (res && res.length > 0) {
        const balanceDetails = _.find(res, (item) => {
          return item.productCode === details.productCode;
        });
        if (balanceDetails) {
          setFuelBalance(balanceDetails.fuelBalance);
        } else {
          setFuelBalance(formatVolume(0));
        }
      }
    });
  });

  const onLiterVolumeChange = (name, value) => {
    const literVolumeString = value.value;
    const literVolumeFloat = parseFloat(parseLiter(literVolumeString));
    const fuelCodeBalance = +details.literVolume;
    modifyField(name, { value: literVolumeString });

    if (literVolumeString && parseLiter(literVolumeFloat) < 1) {
      return applyFieldErrors({
        literVolume: locale.minimumAllowedRedemption1Liter,
      });
    }

    if (literVolumeFloat > fuelBalance || literVolumeFloat > fuelCodeBalance) {
      const balance = fuelBalance < fuelCodeBalance ? fuelBalance : fuelCodeBalance;

      applyFieldErrors({
        literVolume: locale.populate(locale.maximumAllowedRedemption, [formatVolume(balance)]),
      });
    }
  };

  const onActualAmountDispenseChange = (name, value) => {
    const amountDispenseString = value.value;
    const amountDispenseFloat = parseFloat(
      parseLiter(amountDispenseString === "" ? 0 : amountDispenseString)
    );

    const computedVolumeTotal = amountDispenseFloat / (fields?.pumpPrice.value || 0);
    const totalDiscount = roundUp(computedVolumeTotal) * (fields?.discountPerLiter?.value || 0);
    modifyForm({
      [name]: {
        value: amountDispenseString,
      },
      [fields?.computedVolume.name]: {
        value: parseFloat(computedVolumeTotal),
      },
      [fields?.totalDiscount.name]: {
        value: roundUpTwoDecimal(totalDiscount),
      },
    });

    if (amountDispenseString && parseLiter(amountDispenseFloat) < 1) {
      return applyFieldErrors({
        actualAmountDispense: locale.minimumAllowedRedemption50Peso,
      });
    }

    if (name === locale.totalAmountName && amountDispenseFloat > 50000) {
      applyFieldErrors({
        [name]: locale.maximumAllowedRedemption50Thousand,
      });
    }

    if (amountDispenseFloat > (details?.wallet || 0)) {
      const balance = details?.wallet || 0;

      applyFieldErrors({
        [name]: locale.populate(locale.insufficientBalance, [formatAmount(balance)]),
      });
    }

    if (amountDispenseFloat > (details?.remainingLocqpayLimit || 0)) {
      const balance = details?.remainingLocqpayLimit || 0;
      applyFieldErrors({
        [name]: locale.populate(locale.insufficientBalance, [formatAmount(balance)]),
      });
    }
  };

  const onActualAmountDispenseClick = () => {
    if (fields?.productType?.value === null) {
      return applyFieldErrors({
        productType: locale.selectProductFirst,
      });
    }
  };
  const onProductTypeChange = (name, value) => {
    const productType = value.value;
    const getPumpPrice = details?.pumpPrices.find((item) => item.productCode === productType);
    modifyForm({
      [name]: {
        value: productType,
      },
      [fields?.pumpPrice.name]: {
        value: getPumpPrice.pumpPrice || 0,
      },
      [fields?.actualAmountDispense.name]: {
        value: "",
        required: true,
      },
      [fields?.literVolume.name]: {
        value: "",
      },
      [fields?.computedVolume.name]: {
        value: "",
      },
      [fields?.discountPerLiter.name]: {
        value: getPumpPrice.discount || 0,
      },
      [fields?.totalDiscount.name]: {
        value: "",
      },
      [fields?.totalAmount.name]: {
        value: "",
        required: false,
      },
      [fields?.remarks.name]: {
        value: "",
        required: false,
      },
    });
  };

  const onProductTypeLubesChange = (name, value) => {
    const productType = value.value;
    modifyForm({
      [name]: {
        value: productType,
      },
      [fields?.pumpPrice.name]: {
        value: "",
        required: false,
      },
      [fields?.actualAmountDispense.name]: {
        value: "",
        required: false,
      },
      [fields?.literVolume.name]: {
        value: "",
        required: false,
      },
      [fields?.computedVolume.name]: {
        value: "",
        required: false,
      },
      [fields?.discountPerLiter.name]: {
        value: "",
        required: false,
      },
      [fields?.totalDiscount.name]: {
        value: "",
        required: false,
      },
      [fields?.dispensedTo.name]: {
        value: "",
        required: false,
      },
      [fields?.totalAmount.name]: {
        value: "",
        required: true,
      },
      [fields?.remarks.name]: {
        value: "",
        required: true,
      },
    });
  };

  const getProducts = () => {
    let prod = [];

    if (
      details?.paymentMethod === PaymentMethod.FleetCard ||
      details?.paymentMethod === PaymentMethod.FleetCardManualPO
    ) {
      details?.pumpPrices.forEach((item, key) => {
        // if (!item.isVirtualProduct && details[item.productCode]){
        if (details[item.productCode]) {
          prod.push(item.productCode);
        }
      });
      if (details?.lubes) {
        prod.push(locale.lubes);
      }
    } else {
      prod.push(details.productCode);
    }
    return prod;
  };

  const getFleetBalanceAmount = () => {
    const balance =
      details.wallet < details.remainingLocqpayLimit || details?.locqpayLimit === null
        ? details.wallet
        : details.remainingLocqpayLimit;
    return formatAmount(balance);
  };

  const isZeroBalance = fields?.actualAmountDispense.value > locale.fleetCardBalance;
  const balanceClass = isZeroBalance ? styles.textZeroBalance : styles.textBalance;
  const validatePaymentMethodFleetCardBalanceClass = details.remainingLocqpayLimit
    ? styles.textBalance
    : styles.textZeroBalance;

  const validateProducts = () => {
    const prod = getProducts();
    let output = "";
    let productsNotAvailable = [];

    if (prod.length === 0) {
      output = "No Products Available";
      return showProceedModal(false, output);
    } else {
      prod.forEach((p, key) => {
        if (!station.stationProduct[p]) {
          productsNotAvailable.push(prettifyProduct(p));
        }
      });
    }

    let tempList = [...productsNotAvailable];
    let lastProd = tempList.pop();

    if (productsNotAvailable.length > 1) {
      if (tempList.length > 1) {
        output = tempList.join(", ") + " and " + lastProd + " are not available at your station.";
      }
      output = tempList + " and " + lastProd + " are not available at your station.";
    } else {
      output = productsNotAvailable + " is not available at your station.";
    }

    if (
      details?.paymentMethod === PaymentMethod.FleetCard ||
      details?.paymentMethod === PaymentMethod.FleetCardManualPO
    ) {
      return showProceedModal(productsNotAvailable.length !== prod.length, output);
    } else {
      return showProceedModal(productsNotAvailable.length === 0, output);
    }
  };

  const showProceedModal = (isValid, message) => {
    if (isValid) {
      confirmModalGasUp.show();
    } else {
      errorModal.show({
        title: `${locale.productNotAvailable}`,
        content: `${message}`,
        image: ProceedToGas,
        onClick: async () => {
          errorModal.close();
        },
      });
    }
  };

  const confirm = () => {
    if (
      details.paymentMethod === PaymentMethod.FleetCard ||
      details?.paymentMethod === PaymentMethod.FleetCardManualPO
    ) {
      const { actualAmountDispense, dispensedTo, totalAmount, productType } = getFormValues();
      const subTotal = parseFloat(parseLiter(actualAmountDispense || totalAmount));

      // (fields?.pumpPrice?.value || 0) * formatNumber(fields?.computedVolume?.value || 0);

      handleRequest(
        async () => {
          const res =
            productType === locale.lubes
              ? await createPaymentFleetCardLubesRequest({
                  stationCode: details.stationCode,
                  fleetId: details?.fleetId,
                  cardNumber: details?.cardNumber,
                  amount: subTotal,
                  platformType: "plb",
                  poNumber: details?.purchaseOrderNo ? details?.purchaseOrderNo : null,
                  transactionRemarks: fields?.remarks?.value ? fields?.remarks?.value : null,
                })
              : await createPaymentFleetCardRequest({
                  stationCode: details.stationCode,
                  productCode: fields.productType?.value,
                  fleetId: details?.fleetId,
                  amount: subTotal,
                  cardNumber: details?.cardNumber,
                  dispensedTo: dispensedTo || DispenseType.Vehicle,
                  platformType: "plb",
                  poNumber: details?.purchaseOrderNo ? details?.purchaseOrderNo : null,
                });

          if (res) {
            setPaymentId(productType === locale.lubes ? res?.merchantPaymentId : res?.paymentId);
            setProceedToPayment(false);
            setSuccessPayment(true);

            return;
          }
          onSuccessCallback();
          setProceedToPayment(false);
          setSuccessPayment(false);
          setPaymentError(true);
        },
        [paymentId, setPaymentId],
        (err) => {
          setProceedToPayment(false);
          setSuccessPayment(false);
          setPaymentError(true);
        }
      );
    } else {
      handleRequest(
        async () => {
          const { literVolume, dispensedTo } = getFormValues();
          const driverId = details.driverId ? { driverId: details.driverId } : {};
          const res = await redeemFuelCodeRequest({
            stationCode: details.stationCode,
            fuelCode: details.fuelCode,
            literVolume: parseLiter(literVolume),
            dispensedTo: dispensedTo || DispenseType.Vehicle,
            productCode: details.productCode,
            vehicleId: details.vehicleId,
            ...driverId,
          });

          if (res) {
            setRedemptionResponse(res);
            setRedemptionId(res.redemptionId);
            setProceedToPayment(false);
            setSuccessPayment(true);

            return;
          }
          onSuccessCallback();
          setProceedToPayment(false);
          setSuccessPayment(false);
          setPaymentError(true);
        },
        [redemptionResponse, redemptionId, setRedemptionId],
        (err) => {
          setProceedToPayment(false);
          switch (err.data?.errorCode) {
            case ErrorCode.InsufficientBalance:
              setProceedToPayment(false);
              setSuccessPayment(false);
              setPaymentError(false);
              setInsufficientError(true);
              break;
            case ErrorCode.NoProductFuelBalance:
              setProceedToPayment(false);
              setSuccessPayment(false);
              setPaymentError(true);
              break;
            default:
              setProceedToPayment(false);
              setSuccessPayment(false);
              setPaymentError(true);
          }
        }
      );
    }
  };

  const reset = () => {
    clearForm();
    setPaymentDetails(null);
  };

  const handleProceedGasModal = (status) => {
    setGasUp(status);

    if (status && details?.pumpPrices !== undefined) {
      let counter = 0;
      let selectedProduct;

      details?.pumpPrices.forEach((item, key) => {
        if (!item.isVirtualProduct && details[item.productCode]) {
          selectedProduct = item.productCode;
          counter++;
        }
      });

      if (counter === 1 && selectedProduct) {
        onProductTypeChange(fields?.productType.name, { value: selectedProduct });
      }
    }
  };

  const handleBackProceedToPayment = () => {
    setProceedToPayment(false);
    setGasUp(true);
  };

  const handleBackToValidatePayment = () => {
    clearForm();
    setDetails(null);
    setPaymentDetails(false);
    onActiveStepCB(0);
  };

  const onSubmitORCB = (orField) => {
    if (
      details.paymentMethod === PaymentMethod.FleetCard ||
      details?.paymentMethod === PaymentMethod.FleetCardManualPO
    ) {
      handleRequest(async () => {
        const res =
          fields?.productType.value === locale.lubes
            ? await updatePaymentORNonFuelRequest({
                merchantPaymentId: paymentId,
                body: { orNumber: orField.value },
              })
            : await updatePaymentORRequest({
                paymentId,
                body: { orNumber: orField.value },
              });

        if (res) {
          setPaymentId(res.redemptionId);
          setSuccessPayment(false);
          setDetails(null);
          reset();
          onActiveStepCB(0);
          return;
        }
        onSuccessCallback();
        setPaymentError(true);
      }, [paymentId, setPaymentId]);
    } else {
      handleRequest(async () => {
        const res = await updateRedemptionORRequest({
          redemptionId,
          body: { orNumber: orField.value },
        });

        if (res) {
          setRedemptionId(res.redemptionId);
          setSuccessPayment(false);
          setDetails(null);
          reset();
          onActiveStepCB(0);
          return;
        }
        onSuccessCallback();
        setPaymentError(true);
      }, [redemptionId, setRedemptionId]);
    }
  };

  if (showSuccessPayment) {
    onActiveStepCB(2);
    return (
      <PaymentSuccessPage
        paymentId={paymentId}
        redemptionId={redemptionId}
        transactionDetails={redemptionResponse}
        literVolume={parseLiter(fields.literVolume.value)}
        dispensedTo={fields.dispensedTo.value}
        reset={reset}
        details={details}
        setDetails={setDetails}
        closable={false}
        loading={updatingRedemptionOR || updatingPaymentOR || updatingPaymentORNonFuel}
        dataFields={fields}
        onSubmitOR={onSubmitORCB}
        checkCompanyGroup={checkCompanyGroup}
      />
    );
  }
  if (showPaymentError) {
    onActiveStepCB(2);
    return <PaymentErrorPage handleBackToValidatePayment={handleBackToValidatePayment} />;
  }

  if (showInsufficientError) {
    return <InsufficientErrorPage details={details} />;
  }

  if (showGasUp) {
    onActiveStepCB(1);
    return (
      <>
        <div className={styles.detailsContainer}>
          <div className={styles.contentPayment}>
            <div>
              <Title className={styles.subtitle}>{locale.actualGasUpDetails}</Title>
              {(details?.paymentMethod === PaymentMethod.FleetCard ||
                details?.paymentMethod === PaymentMethod.FleetCardManualPO) && (
                <>
                  <Field {...fields.productType}>
                    <div className={styles.productListGas}>
                      {details?.pumpPrices.map((item, key) => {
                        if (!item.isVirtualProduct && details[item.productCode])
                          return (
                            <GasFuelCard
                              active={fields?.productType.value === item.productCode}
                              type={item.productCode}
                              {...fields?.productType}
                              onChange={onProductTypeChange}
                            />
                          );
                        return "";
                      })}
                      {details?.lubes && station?.stationProduct["lubes"] && (
                        <GasFuelCard
                          active={fields?.productType.value === locale.lubes}
                          type={locale.lubes}
                          {...fields?.productType}
                          onChange={onProductTypeLubesChange}
                        />
                      )}
                    </div>
                  </Field>
                </>
              )}
              <div className={styles.container}>
                {details?.paymentMethod === PaymentMethod.FleetCard ||
                details?.paymentMethod === PaymentMethod.FleetCardManualPO ? (
                  <>
                    <div className={styles.tableGasUpDetail} onClick={onActualAmountDispenseClick}>
                      {fields?.productType?.value !== locale.lubes ? (
                        <>
                          <Field {...fields.actualAmountDispense}>
                            <InputAmount
                              innerLabel
                              {...fields.actualAmountDispense}
                              onChange={onActualAmountDispenseChange}
                              className={
                                fields?.productType?.value === null ? styles.disabledInput : ""
                              }
                            />
                          </Field>
                          <div className={styles.rowDetail}>
                            <label>{locale.pumpPrice}</label>
                            <label
                              className={
                                fields?.pumpPrice.value > 0
                                  ? styles.gasDetailActive
                                  : styles.gasDetailNotActive
                              }
                            >
                              {formatAmount(fields?.pumpPrice.value || 0)}
                            </label>
                          </div>
                          <div className={styles.rowDetail}>
                            <label>{locale.computedVolume}</label>
                            <label
                              className={
                                fields?.computedVolume.value > 0
                                  ? styles.gasDetailActive
                                  : styles.gasDetailNotActive
                              }
                            >
                              {formatVolume(fields?.computedVolume.value || 0)}
                            </label>
                          </div>
                          <div className={styles.rowDetail}>
                            <label>{locale.totalDiscount}</label>
                            <label
                              className={
                                fields?.computedVolume.value > 0
                                  ? styles.gasDetailActiveBlue
                                  : styles.gasDetailNotActive
                              }
                            >
                              {fields.totalDiscount.value > 0 && <>-</>}
                              {formatAmount(fields?.totalDiscount.value || 0)}{" "}
                              <span
                                className={
                                  fields?.totalDiscount.value > 0
                                    ? styles.discountPerLiterActive
                                    : styles.discountPerLiterNotActive
                                }
                              >{`(${formatAmount(fields?.discountPerLiter.value)} / L)`}</span>
                            </label>
                          </div>
                          <Field {...fields.dispensedTo}>
                            <Autocomplete
                              {...fields.dispensedTo}
                              options={mapOptions(
                                [DispenseType.Vehicle, DispenseType.DrumContainer],
                                prettifyDispenseType
                              )}
                              onChange={(name, { value }) => {
                                modifyForm({
                                  [name]: {
                                    value,
                                  },
                                  [fields.actualAmountDispense.name]: {
                                    required:
                                      details?.paymentMethod === PaymentMethod.FleetCard ||
                                      details?.paymentMethod === PaymentMethod.FleetCardManualPO,
                                  },
                                });
                              }}
                            />
                          </Field>
                        </>
                      ) : (
                        <>
                          <Field {...fields.totalAmount}>
                            <InputAmount
                              innerLabel
                              {...fields.totalAmount}
                              onChange={onActualAmountDispenseChange}
                              className={
                                fields?.productType?.value === null ? styles.disabledInput : ""
                              }
                            />
                          </Field>
                          <Field {...fields.remarks}>
                            <TextField
                              multiline
                              rowsMax={1}
                              {...fields.remarks}
                              onChange={modifyField}
                              value={fields?.remarks?.value}
                            ></TextField>
                          </Field>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Field {...fields.literVolume}>
                      <InputLitre {...fields.literVolume} onChange={onLiterVolumeChange} />
                    </Field>
                    <Field {...fields.dispensedTo}>
                      <Autocomplete
                        {...fields.dispensedTo}
                        options={mapOptions(
                          [DispenseType.Vehicle, DispenseType.DrumContainer],
                          prettifyDispenseType
                        )}
                        onChange={(name, { value }) => {
                          modifyForm({
                            [name]: {
                              value,
                            },
                            [fields.actualAmountDispense.name]: {
                              required:
                                details?.paymentMethod === PaymentMethod.FleetCard ||
                                details?.paymentMethod === PaymentMethod.FleetCardManualPO,
                            },
                          });
                        }}
                      />
                    </Field>
                  </>
                )}
              </div>
            </div>
            <ActionButton
              left
              secondaryIcon={<span className="icon-back" />}
              items={[
                {
                  onClick: () => {
                    setPaymentDetails(false);
                    setGasUp(false);
                    onActiveStepCB(0);
                  },
                  text: locale.back,
                },
                {
                  onClick: async () => {
                    setProceedToPayment(true);
                    setGasUp(false);
                  },
                  disabled: !isFormSubmittable,
                  text: locale.proceedToPayment,
                },
              ]}
            />
          </div>
          <div className={styles.contentDetails}>
            {details?.paymentMethod === PaymentMethod.FuelCode && (
              <>
                <Title className={styles.subtitle}>{fields.fuelCode.label}</Title>
                <Text className={styles.labelFuelCode}>{details.fuelCode}</Text>
              </>
            )}
            {details?.paymentMethod === PaymentMethod.FuelCode ? (
              <div className={styles.container}>
                <Title className={styles.subtitle}>{fields.productType.label}</Title>
                <br />
                {fetchingFuelBalance ? (
                  <CircularProgress />
                ) : (
                  <div className={styles.productTypeContainer}>
                    <FuelIcon type={details.productCode} />
                    <div className={styles.productTypeDetails}>
                      <Text className={styles.textTitle}>
                        {prettifyProduct(details.productCode)}
                      </Text>
                      <Text className={styles.textBalance}>
                        {`${locale.fleetProductBalance}: `}
                        <span
                          className={classNames(styles.textBalance, {
                            [`${styles.boldRed}`]: fuelBalance < details.literVolume,
                          })}
                        >
                          {formatVolume(fuelBalance)}
                        </span>
                      </Text>
                      <Text className={styles.textBalance}>
                        {`${locale.fuelCodeRemainingLimit}: `}
                        <span
                          className={classNames(styles.textBalance, {
                            [`${styles.boldRed}`]: details.literVolume <= fuelBalance,
                          })}
                        >
                          {formatVolume(details.literVolume)}
                        </span>
                      </Text>
                      <Text className={styles.textBalance}>
                        {`${locale.fuelCodeType}: `}
                        <span className={classNames(styles.textBalance)}>
                          {details?.maxUsage === 1
                            ? `${locale.oneTimeUseOnly}`
                            : `${locale.multiUse}`}
                        </span>
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ marginBottom: "20px" }}>
                <Title className={styles.cardBalance}>{locale.cardInformation}</Title>
                <div className={styles.productTypeContainer}>
                  <CardIcon />
                  <div className={styles.productTypeDetails}>
                    <Text className={styles.textBalance}>
                      {`${locale.cardNo}: `}
                      <span className={styles.textBalance}>{details.fleetCardNo}</span>
                    </Text>
                    <Text className={balanceClass}>
                      {`${locale.fleetCardBalance}: `}
                      <span className={balanceClass}>{getFleetBalanceAmount()}</span>
                    </Text>
                    <Text className={styles.textBalance}>
                      {`${locale.fleetCardTransactionCount}: `}

                      {details?.remainingTransactionLimit === null ||
                      details?.remainingTransactionLimit === undefined ? (
                        <span className={styles.textBalance}>{locale.noLimit}</span>
                      ) : (
                        <span className={styles.textBalance}>
                          {details.remainingTransactionLimit}
                        </span>
                      )}
                    </Text>
                    <Text className={styles.textBalance}>
                      {`${locale.remarks}: ${details.remarks ?? ""}`}
                    </Text>
                  </div>
                </div>
              </div>
            )}
            {details?.fleet?.plbType &&
              details?.fleet?.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO && (
                <>
                  <Title className={styles.subtitle}>{locale.purchaseOrderDetails}</Title>
                  <div className={styles.table}>
                    <div className={styles.row}>
                      <label>{locale.purchaseOrderNo}</label>
                      <label>{details?.purchaseOrderNo}</label>
                    </div>
                  </div>
                </>
              )}
            <Title className={styles.subtitle}>{locale.customerDetails}</Title>
            <div className={styles.table}>
              <div className={styles.row}>
                <label>{locale.businessName}</label>
                <label>{details.fleet?.businessName || ""}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.eligibleDriver}</label>
                <label>{details?.driverName || locale.any}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.driverId}</label>
                <label>{details?.driverLicenseId || locale.any}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.eligibleVehicle}</label>
                <label
                  className={classNames({
                    italic: !details.vehiclePlateNumber,
                  })}
                >
                  {details.vehiclePlateNumber || locale.any}
                </label>
              </div>
              <div className={styles.row}>
                <label>{locale.vehicleModel}</label>
                <label
                  className={classNames({
                    italic: !details.vehicleModel,
                  })}
                >
                  {details.vehicleModel || locale.any}
                </label>
              </div>
              <div className={styles.row}>
                <label>{locale.remarks}</label>
                <label
                  className={classNames(styles.remarks, {
                    italic: !details?.remarks,
                  })}
                >
                  {details?.remarks || locale.any}
                </label>
              </div>
              {details?.paymentMethod === PaymentMethod.FleetCard ||
              details?.paymentMethod === PaymentMethod.FleetCardManualPO ? (
                <div className={styles.row}>
                  <label>{locale.fleetAccountLocqpayBalance}</label>
                  <label className={styles.textBalance}>{formatAmount(details.wallet)}</label>
                </div>
              ) : null}
            </div>
            {details?.fleet?.invoicingSetup === InvoicingSetup.SingleInvoicing &&
              checkCompanyGroup && (
                <div className={styles.noteDoNotIssueReceipt} style={{ width: "60%" }}>
                  <ErrorOutline className={styles.warningSign} />
                  <div className={styles.warningTextSection}>
                    <span className={styles.warningTextSingleInvoice}>
                      {locale.plbAccountIsInSingleInvoicing}
                    </span>
                    <span className={styles.warningTextDoNotIssue}>{locale.doNotIssueReceipt}</span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </>
    );
  }

  if (showProceedToPayment) {
    onActiveStepCB(2);
    return (
      <ValidatePaymentConfirmPage
        confirm={confirm}
        literVolume={parseLiter(fields.literVolume.value)}
        dispensedTo={fields.dispensedTo.value}
        productType={fields?.productType.value}
        loading={redeemingFuelCode || creatingPaymentFleetCard || creatingPaymentFleetCardLubes}
        details={details}
        payment={fields?.actualAmountDispense.value || fields?.totalAmount.value}
        fields={fields}
        handleBackProceedToPayment={handleBackProceedToPayment}
        checkCompanyGroup={checkCompanyGroup}
      />
    );
  }

  let isSubmittable = true;

  if (
    details?.paymentMethod === PaymentMethod.FleetCard ||
    details?.paymentMethod === PaymentMethod.FleetCardManualPO
  ) {
    if (details?.wallet === 0 || details?.wallet === null) {
      isSubmittable = false;
    }

    if (isSubmittable) {
      if (details?.locqpayLimit === null) {
        isSubmittable = true;
      } else if (details?.remainingLocqpayLimit === 0 || details?.remainingLocqpayLimit === null) {
        isSubmittable = false;
      }
    }

    if (isSubmittable) {
      if (details?.transactionLimit === null) {
        isSubmittable = true;
      } else if (
        details?.remainingTransactionLimit === 0 ||
        details?.remainingTransactionLimit === null
      ) {
        isSubmittable = false;
      }
    }
  } else {
    isSubmittable = true;
  }
  return (
    <>
      <div className={styles.detailsContainer}>
        <div className={styles.contentPayment}>
          <div className={styles.fuelCodeContent}>
            <Title className={styles.subtitle}>
              {details?.paymentMethod === PaymentMethod.FuelCode
                ? locale.validateFuelCodeAndValume
                : locale.validateFleetCard}
            </Title>
            {details?.paymentMethod === PaymentMethod.FuelCode ? (
              <Text label>{locale.pleaseEnterFuelCode}</Text>
            ) : (
              ""
            )}
            <div className={styles.container}>
              {details?.paymentMethod === PaymentMethod.FleetCard ||
              details?.paymentMethod === PaymentMethod.FleetCardManualPO ? (
                <>
                  <Field {...fields.fleetCardNo}>
                    <Text className={styles.labelFuelCode}>{details.fleetCardNo}</Text>
                  </Field>
                  {details?.fleet?.plbType &&
                    details?.fleet?.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO && (
                      <Field {...fields.purchaseOrderNo}>
                        <Text className={styles.labelFuelCode}>{details.purchaseOrderNo}</Text>
                      </Field>
                    )}

                  <Title className={styles.cardBalance}>{locale.cardBalance}</Title>
                  <div className={styles.productTypeContainer}>
                    <CardIcon />
                    <div className={styles.productTypeDetails}>
                      {(details.wallet === 0 || details.wallet === null) && (
                        <Text className={styles.textZeroBalanceMessage}>
                          {`${locale.fleetDoesNotHaveEnoughBalance}`}
                        </Text>
                      )}

                      <Text className={validatePaymentMethodFleetCardBalanceClass}>
                        {`${locale.fleetCardBalance}: `}
                        <span className={validatePaymentMethodFleetCardBalanceClass}>
                          {getFleetBalanceAmount()}
                        </span>
                      </Text>
                      <Text
                        className={
                          details.remainingTransactionLimit ||
                          details?.remainingTransactionLimit === null ||
                          details?.remainingTransactionLimit === undefined
                            ? styles.textBalance
                            : styles.textZeroBalance
                        }
                      >
                        {`${locale.fleetCardTransactionCount}: `}
                        {details?.remainingTransactionLimit === null ||
                        details?.remainingTransactionLimit === undefined ? (
                          <span className={styles.textBalance}>{locale.noLimit}</span>
                        ) : (
                          <>
                            <span
                              className={
                                details.remainingTransactionLimit
                                  ? styles.textBalance
                                  : styles.textZeroBalance
                              }
                            >
                              {details.remainingTransactionLimit}
                            </span>
                          </>
                        )}
                      </Text>
                      <Text className={styles.textBalance}>
                        {`${locale.remarks}: ${details.remarks ?? ""}`}
                      </Text>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Field {...fields.fuelCode}>
                    <Text className={styles.labelFuelCode}>{details.fuelCode}</Text>
                  </Field>
                  <Field {...fields.productType}>
                    {fetchingFuelBalance ? (
                      <CircularProgress />
                    ) : (
                      <div className={styles.productTypeContainer}>
                        <FuelIcon type={details.productCode} />
                        <div className={styles.productTypeDetails}>
                          <Text className={styles.textTitle}>
                            {prettifyProduct(details.productCode)}
                          </Text>
                          <Text className={styles.textBalance}>
                            {`${locale.fleetProductBalance}: `}
                            <span
                              className={classNames(styles.textBalance, {
                                [`${styles.boldRed}`]: fuelBalance < details.literVolume,
                              })}
                            >
                              {formatVolume(fuelBalance)}
                            </span>
                          </Text>
                          <Text className={styles.textBalance}>
                            {`${locale.fuelCodeRemainingLimit}: `}
                            <span
                              className={classNames(styles.textBalance, {
                                [`${styles.boldRed}`]: details.literVolume <= fuelBalance,
                              })}
                            >
                              {formatVolume(details.literVolume)}
                            </span>
                          </Text>
                          <Text className={styles.textBalance}>
                            {`${locale.fuelCodeType}: `}
                            <span className={classNames(styles.textBalance)}>
                              {details?.maxUsage === 1
                                ? `${locale.oneTimeUseOnly}`
                                : `${locale.multiUse}`}
                            </span>
                          </Text>
                        </div>
                      </div>
                    )}
                  </Field>
                </>
              )}
            </div>
          </div>
          <ActionButton
            left
            items={[
              {
                onClick: () => {
                  clearForm();
                  setDetails(null);
                  setPaymentDetails(false);
                },
                text: locale.cancel,
              },
              {
                onClick: async () => {
                  validateProducts();
                },
                text: locale.proceedToGasUp,
                disabled: !isSubmittable,
              },
            ]}
          />
        </div>
        <div className={styles.contentDetails}>
          <Title className={styles.subtitle}>{locale.customerDetails}</Title>
          <div className={styles.table}>
            <div className={styles.row}>
              <label>{locale.businessName}</label>
              <label>{details.fleet?.businessName || ""}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.eligibleDriver}</label>
              <label>{details?.driverName || locale.any}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.driverId}</label>
              <label>{details?.driverLicenseId || locale.any}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.eligibleVehicle}</label>
              <label
                className={classNames({
                  italic: !details.vehiclePlateNumber,
                })}
              >
                {details.vehiclePlateNumber || locale.any}
              </label>
            </div>
            <div className={styles.row}>
              <label>{locale.vehicleModel}</label>
              <label
                className={classNames({
                  italic: !details.vehicleModel,
                })}
              >
                {details.vehicleModel || locale.any}
              </label>
            </div>
            <div className={styles.row}>
              <label>{locale.remarks}</label>
              <label
                className={classNames(styles.remarks, {
                  italic: !details?.remarks,
                })}
              >
                {details?.remarks || locale.any}
              </label>
            </div>
            {details?.paymentMethod === PaymentMethod.FleetCard ||
            details?.paymentMethod === PaymentMethod.FleetCardManualPO ? (
              <div className={styles.row}>
                <label>{locale.fleetAccountLocqpayBalance}</label>
                <label className={styles.textBalance}>{formatAmount(details.wallet)}</label>
              </div>
            ) : null}
          </div>
          {details?.fleet?.invoicingSetup === InvoicingSetup.SingleInvoicing &&
            checkCompanyGroup && (
              <div className={styles.noteDoNotIssueReceipt} style={{ width: "60%" }}>
                <ErrorOutline className={styles.warningSign} />
                <div className={styles.warningTextSection}>
                  <span className={styles.warningTextSingleInvoice}>
                    {locale.plbAccountIsInSingleInvoicing}
                  </span>
                  <span className={styles.warningTextDoNotIssue}>{locale.doNotIssueReceipt}</span>
                </div>
              </div>
            )}
        </div>
      </div>
      <NotifModal {...errorModal} />
      <ProceedToGasModal handleProceedGasModal={handleProceedGasModal} {...confirmModalGasUp} />
      <ZeroBalanceModal isBalance={balanceErrorCheck} {...zeroBalanceModal} />
    </>
  );
};
const mapOptions = (values = [], prettifier = () => {}) => {
  return [
    ...values.map((value) => ({
      label: prettifier(value),
      value,
    })),
  ];
};

export default ValidatePaymentDetails;
